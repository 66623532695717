import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo";

const PrivacyPage = ({ location }) => (
  <Layout page="cookies" location={location}>
    <Seo
      manualTags={{
        seoTitle: "Privacy",
        seoDescription: "",
      }}
    />
    <section className="aq-feature-bg pt-8 pb-8 bg-grey">
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col">
              <h1 className="h1-two-line-v2 green mb-15 mt-6 text-center">
                <span>Privacy Policy</span>
              </h1>
              <br />
              <br />
              <div>
                <h4>
                  <strong className="text-teal">About Us</strong>
                </h4>
                <p>
                  South East Water Limited ("We") takes care to protect and
                  respect your privacy.
                </p>
                <p>
                  This policy (together with our{" "}
                  <a
                    className=""
                    href="https://www.southeastwater.co.uk/about/legal-and-policies/terms-of-use"
                  >
                    terms of use
                  </a>
                  )&nbsp;and any other documents referred to on it) sets out the
                  basis on which any personal data we collect from you, or that
                  you provide to us, will be processed by us.&nbsp;Please read
                  the following carefully to understand our views and practices
                  regarding your personal data and how we will treat it.
                </p>
                <p>
                  For the purpose of the Data Protection Requirements*, the data
                  controller is South East Water Ltd of Rocfort Road, Snodland,
                  Kent, ME6 5AH (company number <strong>02679874)</strong>.
                </p>
                <p>
                  <em>
                    * Data Protection Requirements means the Data Protection Act
                    1998 (or The Data Protection Act 2018), the EU Data
                    Protection Directive 95/46/EC, the Regulation of
                    Investigatory Powers Act 2000, the Telecommunications
                    (Lawful Business Practice) (Interception of Communications)
                    Regulations 2000 (SI 2000/2699), the Electronic
                    Communications Data Protection Directive 2002/58/EC, the
                    Privacy and Electronic Communications (EC Directive)
                    Regulations 2003, the Privacy and Electronic Communications
                    (EC Directive) (Amendment) Regulations 2011, the General
                    Data Protection Regulation (from 25 May 2018) and all other
                    applicable laws and regulations relating to processing of
                    personal data and privacy in any applicable jurisdiction as
                    amended and replaced, including where applicable the
                    guidance and codes of practice issued by the UK Information
                    Commissioner or such other relevant data protection
                    authority;
                  </em>
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">How to contact us</h4>
                <p>
                  If you want to request&nbsp;further information&nbsp;about our
                  privacy notice or exercise any of your rights you can:
                </p>
                <p>
                  email us:{" "}
                  <a
                    href="mailto:Dataprotection@southeastwater.co.uk"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Dataprotection@southeastwater.co.uk</strong>
                  </a>
                </p>
                <p>
                  write to us: Data Protection Officer, South East Water,
                  Rocfort Road, Snodland, Kent, ME6 5AH
                </p>
                <p>
                  You can also contact the Information Commissioner's Office via{" "}
                  {""}
                  <a
                    href="https://ico.org.uk/"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>https://ico.org.uk/</strong>
                  </a>{" "}
                  or at Wycliffe House Water Lane, Wilmslow, Cheshire SK9 5AF or
                  0303 123 1113 for information, advice or to make a complaint.
                </p>
                <p>
                  South East Water strives for the highest standards when
                  collecting and using personal information. For this reason, we
                  take any complaints we receive about this very seriously. We
                  encourage people to bring it to our attention if they think
                  that our collection or use of information is unfair,
                  misleading or inappropriate. We would also welcome any
                  suggestions for improving our procedures.
                </p>
                <p>
                  We are also happy to provide any additional information or
                  explanations needed. Any requests should be sent to us using
                  one of the methods above.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Information we collect from you and what we do with it
                </h4>
                <p>
                  When you use the AquaSmart website and fill in any data
                  capture forms we will collect and process:
                </p>
                <ul>
                  <li>email address</li>
                  <li>phone number</li>
                  <li>Details of your household such as number of children</li>
                  <li>Meter details such as water consumption and usage</li>
                  <li>Details of your water usage by way of surveys</li>
                </ul>
                <p>
                  We will use this information to respond to queries you may
                  raise when contacting us via the AquaSmart website.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Our lawful basis for processing your personal information
                </h4>
                <p>
                  We will only process your information if we have a lawful
                  basis to do so. There are 6 lawful basis of processing as set
                  out in the Data Protection Act and the ones that most apply to
                  the AquaSmart work by South East Water are set out below
                </p>
                <p>
                  <strong>Legal obligation</strong>
                </p>
                <p>
                  As a statutory water undertaker we have a duty to promote
                  water efficiency and we do this by using educational tools
                  like the AquaSmart to help children understand why it is
                  important to save water.
                </p>
                <p>
                  <strong>Legitimate interests</strong>
                </p>
                <p>
                  Where the activities are not strictly necessary for compliance
                  with a legal obligation we may process your information in
                  accordance with our legitimate interests e.g. carrying out
                  market research or customer satisfaction or feedback surveys,
                  or telling you ways you can be more water efficient.
                </p>
                <p>
                  We always check that our legitimate interests are not
                  overridden by your rights and that such processing is set out
                  in this privacy notice.
                </p>
                <p>
                  <strong>Consent</strong>
                </p>
                <p>
                  In some cases we may need your consent to process the
                  information e.g. where you provide us with sensitive personal
                  data such as health data, in particular in relation to certain
                  support such as our priority services register and discount
                  schemes or tariffs or in relation to certain marketing
                  activities. When we use consent we will make sure we collect
                  your consent and record it and that we will tell you how you
                  can withdraw your consent if you change your mind.
                </p>
                <p>
                  <strong>Vital Interests</strong>
                </p>
                <p>
                  Sometimes we may need to protect your vital interests and
                  process your information e.g. because there is a risk to you
                  and your household due to water quality issues or where the
                  water supply has been disrupted.
                </p>
                <p>
                  <strong>Public Task</strong>
                </p>
                <p>
                  In some cases we may be acting in the best interests of the
                  public when we use your data, for example when we operate our
                  Priority Services Register to ensure that our communities are
                  served in the ways appropriate to them and that support can be
                  provided at times of need. In this case and to process your
                  special category health data we also use the lawful basis of
                  Substantial Public Interest
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  What to expect when you contact us
                </h4>
                <p>
                  <strong>Contacting us via social media</strong>
                </p>
                <p>
                  It is never a good idea to post your personal contact
                  information on a public social media site. If you contact us
                  using social media to report an issue and we need more
                  information, we will ask you to private message us with
                  suitable communication information which we will use to
                  contact you in order to resolve your concern or gather
                  additional information for our investigation.
                </p>
                <p>
                  <strong>Contacting us by the website</strong>
                </p>
                <p>
                  If you fill in a form on our website or use the contact us
                  page for sending a message the information you provide will be
                  added to your account so that we can action your request and
                  your contact details will be saved for future use by us in
                  managing your account. All the forms you use on the website
                  will have a link back to this privacy notice for more
                  information, depending on the form.
                </p>
                <p>
                  <strong>
                    Completing water use surveys or water efficiency tools on
                    our website
                  </strong>
                </p>
                <p>
                  The information you provide about your water usage will not be
                  stored on your water account. It will be used by our water
                  resources management team in relation to future planning for
                  water resources in your area.
                </p>
                <p>
                  <strong>Contacting us by telephone</strong>
                </p>
                <p>
                  When you contact us by telephone your telephone number will be
                  added to your account so that we can contact you in future
                  with regard to our services to you, for example confirming
                  that action on your account has been taken, such as a bill
                  being paid or a meter read having been taken.
                </p>
                <p>
                  Where you have provided a mobile telephone number this will be
                  added in the same way and you will receive occasional texts to
                  confirm appointments made with you or to let you know not to
                  worry about a letter that may already be in the post if your
                  payment was received, or if we have been trying to get hold of
                  you for example to make an appointment.
                </p>
                <p>
                  You may also be asked from time to time if you would like a
                  text to confirm any action that you have requested on your
                  account, for example a receipt of a telephone payment,
                  confirmation of a meter read etc. These will only be sent to
                  you if you want them sent.
                </p>
                <p>
                  We may also text you in the event that there is a water supply
                  interruption, water quality concern or&nbsp;to provide you
                  with urgent information about your water account. This will
                  only be used in essential service cases.
                </p>
                <p>
                  You can also sign up to receive additional text services, such
                  as ‘In Your Area’ or for general information and updates if it
                  is easier for you to receive texts for example if you are hard
                  of hearing. This way you will know that you are always kept up
                  to date. If you opt out of receiving such additional messages
                  later on you will still receive your essential service
                  messages.
                </p>
                <p>
                  We may also use it to tell you about other services we offer
                  that are similar to those that you have already purchased or
                  enquired about. Where we have obtained your details in the
                  course of setting you up a water account and providing such
                  services to you, we may contact you by electronic means with
                  information about services similar to those which were the
                  subject of this previous transaction unless you tell us you do
                  not want us to by opting out. If you opt out of receiving such
                  messages you will still receive your essential service
                  messages.
                </p>
                <p>
                  <strong>Contacting us by email</strong>
                </p>
                <p>
                  If you e-mail us, we will respond to you using the e-mail
                  address you gave us. Please note that we will still need to
                  identify you are the account holder before we can discuss your
                  account over email so we may need to ask you to provide
                  personal information about yourself. As email is not always
                  the most secure method of communication please feel free to
                  either contact us via a web form or telephone us if you do not
                  want to put your personal data in an email. Your e-mail
                  address will be added to your account and may be used for
                  future communications.
                </p>
                <p>
                  We may use it to tell you about other services we offer that
                  are similar to those that you have already purchased or
                  enquired about. Where we have obtained your details in the
                  course of setting you up a water account and providing such
                  services to you, we may contact you by electronic means with
                  information about services similar to those which were the
                  subject of this previous transaction unless you tell us you do
                  not want us to by opting out. If you opt out of receiving such
                  messages you will still receive your essential service
                  messages.
                </p>
                <p>
                  Any email sent to South East Water, including any attachments,
                  may be monitored and used by us for reasons of security and
                  for monitoring compliance. Emails are also backed up and
                  thereafter deleted in line with our retention rules. Email
                  monitoring or blocking software may also be used. Please be
                  aware that you have a responsibility to ensure that any email
                  you send to us is within the bounds of the law.
                </p>
                <p>
                  <strong>Contacting us by post</strong>
                </p>
                <p>
                  All letters and their accompanying documents are scanned and
                  then saved electronically. The hard copy letters are then
                  destroyed and any accompanying original documents (for example
                  evidence of identity) are not stored at South East Water and
                  these will be posted back to you.
                </p>
                <p>
                  <strong>
                    How we can identify you once you have contacted us
                  </strong>
                </p>
                <p>
                  In order to ensure that we are manging your account in a
                  secure way you will be asked to provide identifying
                  information about yourself. We have improved this security
                  recently and we will now be asking for your date of birth as
                  this is deemed a more secure identity question to use. It is
                  important that you keep your contact and identification
                  details up to date with us. Where you have contacted us by
                  direct email we may need to call or email you in response to
                  or to confirm you are the account holder before we can discuss
                  your account with you.
                </p>
                <p>
                  Please note that if our agents do not feel that they have been
                  able to satisfactorily identify you they may not be able to
                  provide you with information about your account. If you
                  struggle to remember items of information, ask about our
                  password scheme or think about appointing a third party to
                  manage your account for you. Contact us to find out more about
                  this.
                </p>
              </div>
              <br />
              <div>
                <h3 className="text-teal">Visiting our offices</h3>
                <p>
                  When you sign in to our visitor system you will need to
                  provide information such as your name and vehicle registration
                  number (if you are parked at our site).
                </p>
                <p>
                  Our premises are monitored by CCTV so your image may be
                  captured whenever you enter our site boundary. There are signs
                  to show you when you are entering an area monitored by CCTV.
                  CCTV Images are held for 30 days and then deleted.
                </p>
                <p>
                  We also have ANPR (automatic number plate registration) at our
                  Head office and at some of our sites for security purposes so
                  we can identify cars that have entered.
                </p>
                <p>
                  If you have an accident while you are on South East Water
                  premises this must be reported and will be recorded for the
                  purposes of health and safety and insurance requirements.
                </p>
                <p />
              </div>
              <br />
              <div>
                <h3 className="text-teal">Visiting our websites</h3>
                <p>
                  To enable us to monitor and improve our websites, we may
                  gather certain information about your use using cookies and
                  similar technologies. These technologies are used to measure
                  and analyse information on visits to our websites, to tailor
                  the websites to make them better for visitors and to improve
                  the site's technical performance. We will not use the data to
                  identify you personally or to make any decisions about you.
                </p>
                <p>
                  Please visit our <Link to="/cookies/">Cookies page</Link> to
                  read more about how we use cookies to enable us to monitor and
                  improve our websites
                </p>
                <p>
                  Our website may also, from time to time, contain links to and
                  from the websites of our partner networks and affiliates. If
                  you follow a link to any of these websites, please note that
                  these websites have their own privacy policies and that we do
                  not accept any responsibility or liability for these policies.
                  Please check these policies before you submit any personal
                  data to these websites.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Our household customers</h4>
                <p>
                  For all our household customers we will use your name and
                  address, date of birth and other items depending on how you
                  contact us (see above) for managing your water account and
                  providing your water services. This means we can use it to
                  help detect and quantify water leakage; manage the water
                  network, help us to fulfil our duty to encourage water
                  efficiency and for billing and payment purposes such as
                  administering your account, verifying your identity, debt
                  collection, responding to your queries, processing your
                  applications and contacting you by telephone, email or post in
                  relation to our services, obtain your views or comments about
                  our services and generally carry out our functions as a water
                  company. We may also provide you with information about other
                  goods and services we offer that are similar to those that you
                  have already purchased or enquired about. We rely on our
                  statutory entitlements as a water provider and our legitimate
                  interests to do so.
                </p>
                <p>
                  For some customers we may need additional items depending on
                  their requirements.
                </p>
                <p>
                  <strong>Priority Service Register</strong>
                </p>
                <p>
                  Our customers can choose to become a member of the Priority
                  Service Register (PSR) if they need additional assistance in
                  communicating with us or they have certain access or health
                  needs or may need support in the event of a water disruption.
                  Have a look at our{" "}
                  <a
                    className=""
                    href="https://www.southeastwater.co.uk/help/priority-services"
                  >
                    Priority Services
                  </a>{" "}
                  page for more detail.{" "}
                </p>
                <p>
                  Customers can be added to the PSR with their
                  consent.&nbsp;However we are now going to use the lawful basis
                  of processing called public task as this will allow third
                  parties to add people to the priority service list where they
                  cannot add themselves and allows us to act in the best
                  interests of the public when we use your data to ensure that
                  our communities are served in the ways appropriate to them and
                  that support can be provided at times of need. In this case
                  and to process your special category health data we also use
                  the lawful basis of Substantial Public Interest. Any customer
                  can ask to be taken off the PSR at any time.
                </p>
                <p>
                  We ask for information about the type of need you have and you
                  may be contacted by telephone, email, text or in person when
                  you join and occasionally thereafter to ensure that we are
                  providing you with the best support for your needs.
                </p>
                <p>
                  If you have provided a password then this will be used. If you
                  have informed us that it takes time to answer the door, our
                  technicians and field agents will wait longer for you to
                  answer the door. They will always have identification and if
                  you are not sure you can always call us and check your visitor
                  is genuine.
                </p>
                <p>
                  We may also share your details with our trusted partners or
                  your sewerage provider in the event of an emergency so that if
                  you have a high priority water dependent need we can supply
                  you with bottled water.
                </p>
                <p>
                  If anything changes about your situation just tell us and we
                  will update your details. If you don’t want to be on the PSR
                  anymore, just ask to be removed and we will do this for you.
                </p>
                <p>
                  We may also join a nationwide initiative between water and
                  energy companies so that you only have to register in one
                  place. If we do we will give you notice before your details
                  are shared with your energy provider.
                </p>
                <p>
                  <strong>Customers with financial difficulty</strong>
                </p>
                <p>
                  We have several schemes to help support customers who have
                  financial difficulty. These schemes are: WaterSure, Helping
                  Hands, Water Direct and Social Tariff. We may recommend these
                  schemes to you in accordance with our legitimate interests but
                  you will only be placed on these if you are eligible and have
                  provided your consent.
                </p>
                <p>
                  In support of the application for a scheme, we may ask you
                  questions about your household including the occupants, ages
                  and incomes and request evidence of one or more of the
                  following:
                </p>
                <ul>
                  <li>A medical condition resulting in increased water use</li>
                  <li>
                    A family, with three or more children living at the property
                    under the age of 19
                  </li>
                  <li>
                    The household income is less than the set amount for the
                    scheme and whether you are responsible for a child under the
                    age of 5 (living at the property) or someone in the
                    household is over 62 years of age
                  </li>
                  <li>
                    Received Disability Living Allowance (DLA) or Personal
                    Independence Payments (PIP)
                  </li>
                  <li>Income Support</li>
                  <li>Income-based Job Seekers Allowance (JSA)</li>
                  <li>Housing Benefit</li>
                  <li>Council tax Benefit</li>
                  <li>Pension Credit</li>
                  <li>Working Tax Credit</li>
                  <li>Child Tax Credit (other than just the family element)</li>
                  <li>Income-related Employment and support Allowance (ESA)</li>
                  <li>Universal Credit</li>
                  <li>Date of birth for you and/or your children</li>
                </ul>
                <p>
                  <strong>My Account customers</strong>
                </p>
                <p>
                  For your convenience, you can choose to register to have an
                  online account called “My Account”. This means that you can
                  sign in online to see your bills and any outstanding payments
                  and you will receive e-mail notifications when you have a new
                  bill.&nbsp;You will also receive email and text notifications
                  when there has been a change to your payment plan or there is
                  an important message to send you relating to your water
                  account. You will also have an option to sign up for optional
                  notifications as well via your My Account in the Notifications
                  page.
                </p>
                <p>
                  We will take your e-mail address, water account number, name
                  and postal address when you sign up for e-billing – for more
                  details please see our{" "}
                  <strong>
                    <a
                      className=""
                      href="https://www.southeastwater.co.uk/about/legal-and-policies/my-account-terms-and-conditions"
                    >
                      terms of use
                    </a>
                    .
                  </strong>
                </p>
                <p>
                  There may also be links within the e-billing portal to
                  leaflets which are normally sent out with paper bills,
                  offering additional information about the water services we
                  are providing to you and water efficiency advice.
                </p>
                <p>
                  For the information you provide to us when you register as a
                  customer please see the section above relating to how we
                  process information when you contact us with by telephone and
                  by email.
                </p>
                <p>
                  <strong>
                    People who fall into arrears or fail to pay their water bill
                  </strong>
                </p>
                <p>
                  In respect to a customer’s responsibility for paying their
                  bills, South East Water has a regulatory requirement to
                  maintain a safe supply of fresh water to your property. In our{" "}
                  <strong>
                    <a
                      href="https://cdn.southeastwater.co.uk/Publications/Our+charges/hh-charges-scheme-w-cmf-customer-table.pdf"
                      className=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      Household Charges Scheme
                    </a>{" "}
                  </strong>
                  we explain that water charges are payable by the occupier of
                  the premises, or the person who uses the supply, except where
                  the owner of the premises is liable by enactment or by
                  agreement with South East Water. Our Household Charges Scheme
                  is further supported by the Water Industry Act 1991, which
                  gives Water Companies the powers to charge and recover
                  charges. This is referred to in Section 142 of the Water
                  Industry Act 1991.
                </p>
                <p>
                  The information that we hold about customers may be used to
                  recover arrears in line with our regulatory obligations and in
                  doing so we may use lawyers, local authorities, the Department
                  of Work and Pensions (DWP) and debt recovery or tracing
                  agents, credit reference or fraud prevention agencies,&nbsp;in
                  order to help us trace and collect money owed to us.
                </p>
                <p>
                  We rely on our statutory entitlements and legitimate interests
                  to do so.
                </p>
                <p>
                  <strong>Reporting a water leak</strong>
                </p>
                <p>
                  If you are kind enough to report a water leak we may take
                  contact details from you in order to contact you if we need
                  more information about the location or nature of the leak. You
                  do not have to leave your contact information but it would be
                  helpful for us if you can so that we can quickly identify the
                  leak and repair it, minimising the amount of water that is
                  wasted.
                </p>
                <p>
                  <strong>Reporting&nbsp;a water quality concern</strong>
                </p>
                <p>
                  If you report a water quality concern, for example
                  dissatisfaction with taste, odour or the aesthetic appearance
                  of the water provided to you, we will need to investigate.
                  This means that we may use your telephone number to call you
                  and discuss your concern. It is likely that a water sampler
                  will need to visit your home to take some samples. We will
                  keep you informed of planned visits and of any results we find
                  by letter or by telephone. If you have provided an e-mail
                  address a copy of any letter may also be sent using this for
                  your convenience.
                </p>
                <p>
                  The water quality concern may need to be reported to the DWI
                  and they may wish to contact you direct to investigate if you
                  have made a complaint and we would be required by law to pass
                  on your contact details to them for this purpose. The issue
                  may also need to be reported to your local health authority or
                  to Public Health England. We rely on our statutory
                  entitlements and legitimate interests to do so.
                </p>
                <p>
                  <strong>Making&nbsp;a complaint</strong>
                </p>
                <p>
                  If you make a complaint to us, we will follow our complaints
                  procedure. We may need to share details about your complaint
                  internally in order to fully investigate. If you escalate your
                  complaint to The Consumer Council for Water (CCWater) we will
                  share information with them in order to resolve your
                  complaint. This exchange of personal data with CCWater is a
                  legal requirement under section 27B of the Water Industry Act
                  (as amended by the Water Act 2003).
                </p>
                <p>
                  We will only use the personal information we collect to
                  process the complaint in accordance with our complaints
                  process. For more detail see{" "}
                  <strong>
                    <a
                      className=""
                      href="https://www.southeastwater.co.uk/about/resources/customer-leaflets"
                    >
                      www.southeastwater.co.uk/customerleaflets
                    </a>
                    .
                  </strong>
                </p>
                <p>
                  <strong>Making an insurance claim</strong>
                </p>
                <p>
                  If you make an insurance claim to us we will need to share the
                  details of your claim with our insurance provider and loss
                  adjustor. We will keep the details of the claim until it has
                  been settled and thereafter for a period of 6 years. We rely
                  on our statutory entitlements and legitimate interests to do
                  so.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Developing Smarter Water Networks Trial customers
                </h4>
                <p>
                  If you are a customer in our trial area for the smarter water
                  network, the sensor on the meter will collect the meter read
                  back at 15 minutes intervals but will only transmit this back
                  to us on an hourly basis.
                </p>
                <p>
                  During the trial we will be using this data for the following
                  reasons:
                </p>
                <ul>
                  <li>
                    Leakage detection – we should be able to locate whether the
                    leak is in your property or on our network (and where on our
                    network) which will help us fix it quicker. If the leak is
                    in your property we can point you in the right direction to
                    get it fixed. It could help us identify how to better
                    prioritise which leak to fix first in some cases.
                  </li>
                  <li>
                    Reducing the volume of water lost to leaks – if we find the
                    leak quicker we can fix it quicker and save water.
                  </li>
                  <li>
                    Stress testing the battery and telecommunications channels
                    to make sure the devices will last as long as they say they
                    should.
                  </li>
                  <li>
                    We may talk to you as well to see if us telling you about
                    this changes your behaviour at all. For example, does this
                    improve customers water efficiency in any way?
                  </li>
                  <li>
                    We may analyse the data to see if we can offer new services
                    such as tariffs or usage plans to customers in the future.
                    These would all be new opt in services that you could sign
                    up to if you wanted.
                  </li>
                </ul>
                <p>
                  During the trial we will also use the meter reads we collect
                  to carry on with managing your account and providing your
                  water services in the normal way. See our section on household
                  customers&nbsp;for more information.
                </p>
                <p>
                  As this is a trial we are using the legitimate interest basis
                  of processing as this is a key way for us to show that we can
                  reduce the water used per person and to manage and reduce
                  leakage in more efficient and time sensitive ways.
                </p>
                <p>
                  For more information see the Developing Smarter Water Networks
                  Trial{" "}
                  <a
                    className=""
                    href="https://www.southeastwater.co.uk/help/water-supply/smarter-water-networks-trial"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Other types of customers</h4>
                <p>
                  <strong>Non Household Customers</strong>
                </p>
                <p>
                  South East Water took over the management and collection of
                  any debt incurred by non-household customers of both South
                  East Water Choice and then Invicta Water trading as Water
                  Choice for all debt incurred up to and including the 30th June
                  2018. For such processing of this debt, we will need to
                  process your name, supply point ID (SPID), supply and billing
                  address, and payment details or income and expenditure for
                  payment plans. We will also be acting as a data processor for
                  Castle Water for a transitional period to help them with any
                  queries they may have in relation to the customers’ bills and
                  will process customer information as so required, for example
                  if a customer has moved away and their bill needs adjusting.
                </p>
                <p>
                  <strong>Developers</strong>
                </p>
                <p>
                  Where customers contact us for a new connection the personal
                  information provided will be contact information and will be
                  stored for 12 months after completion of the connection
                  services. We will process this information for regulatory
                  requirements in connection with providing a connection to the
                  water supply. Developers may also be contacted for survey
                  purposes. See 'Information we share with others'.
                </p>
                <p>
                  <strong>Bond or debenture holders</strong>
                </p>
                <p>
                  We will hold details about you until the bond or debenture has
                  been released. We may need to use tracing companies to locate
                  you if you have moved. Please do keep us up-to-date with your
                  details. Any processing carried out is done so in accordance
                  with the conditions of the bond or debenture.
                </p>
                <p>
                  <strong>Tenants</strong>
                </p>
                <p>
                  Where we own property that we rent out we will process
                  information required to manage the tenancy. This will be in
                  accordance with the relevant tenancy agreement, therefore by
                  way of a contract.
                </p>
                <p>
                  <strong>
                    Farmers or land owners working with our catchment management
                    team
                  </strong>
                </p>
                <p>
                  Your information will only be processed with your agreement
                  and as set out in any terms and conditions that you are
                  provided, therefore by way of a contract.
                </p>
                <p>
                  <strong>Interested parties</strong>
                </p>
                <p>
                  If you are an interested party or stakeholder to South East
                  Water, e.g. parish council, wildlife trust, local politician,
                  neighbour to one of our sites, you will be asked to provide
                  consent if you want to sign up to receive newsletters about
                  our activities. We will contact you annually to check you
                  still want to receive such updates. Please contact us if this
                  changes and you no longer wish to receive updates.
                </p>
                <p>
                  If you have provided your details in connection with a
                  specific scheme or project then your details will only be held
                  in relation to that matter.
                </p>
                <p>
                  We may contact you separately in order to carry out any of our
                  legal requirements, for example to inform you of planning
                  permission we are seeking, advising of work to be carried out
                  in your area, or in an emergency such as a loss of water
                  supply.
                </p>
                <p>
                  <strong>
                    Potential candidates for jobs at South East Water
                  </strong>
                </p>
                <p>
                  We will process the personal information you provide to us as
                  part of your job application and there is a separate privacy
                  notice for this on the careers web page.
                </p>
                <p>
                  <strong>Customers of Waterlink</strong>
                </p>
                <p>
                  Where you have had plumbing or water supply pipe services
                  provided to you by our commercial division of South East
                  Water, called Waterlink, your personal information may be
                  processed in accordance with the privacy notice at
                  http://www.waterlink.co.uk/about/disclaimer.asp
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Moving out of our supply area</h4>
                <p>
                  If you move out of our supply area, your account will be
                  closed once any outstanding balance has been paid but your
                  data in relation to water consumption, charges and supply pipe
                  works or leak allowances will be kept on record longer in
                  order to feed into our water resource management plan. We will
                  use this information at an aggregated level for business
                  planning purposes.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Information we collect or obtain from others about you
                </h4>
                <p>
                  We may receive or collect information about you from others
                  when we are confirming whether properties are empty or are in
                  actual fact using our water. To save us having to do this
                  please ensure you notify us as soon as you move into or take
                  responsibility for a property.
                </p>
                <p>
                  We may receive information about you if you use any of the
                  other websites we operate or the other services we provide. In
                  this case we will have informed you when we collected that
                  data that it may be shared internally and combined with data
                  collected on this website.
                </p>
                <p>
                  We may also receive information about you in some of the
                  circumstances set out above e.g. if someone else reports a
                  possible water leak, makes a complaint or confirms information
                  needed in order to provide you with a discount or support
                  scheme.
                </p>
                <p>
                  We also work closely with trusted partners (including, for
                  example, business partners, sub-contractors in technical,
                  payment and delivery services, advertising networks, analytics
                  providers, search information providers, local authorities and
                  credit reference agencies) and may receive information about
                  you from them.
                </p>
                <p>
                  In future, we may also receive information about you from
                  public authorities and other utility companies in order to
                  improve public service delivery and ensure that priority
                  customers can access available support and discount schemes.
                </p>
                <p>
                  <strong>Landlords and Housing Associations</strong>
                </p>
                <p>
                  In many cases we are supplied your details from your landlord
                  or housing association which will include the date that you
                  moved into the property. In cases of social or council run
                  housing schemes this may also include information you have
                  provided to them about income for example if they are
                  recommending to us that you may need help paying your water
                  bills.
                </p>
                <p>
                  Many landlords use ‘Landlord TAP’ which is a website that
                  allows Landlords and Managing Agents, of residential
                  properties in England &amp; Wales, to provide water companies
                  with details of those responsible for the payment of water
                  and/or sewerage charges for their tenanted properties.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Information we share with others</h4>
                <p>
                  We manage all arrangements for sharing your data with the
                  strictest of care and would never sell your data to be used
                  for marketing purposes.
                </p>
                <p>
                  <strong>
                    The obligations that we have to our regulators
                  </strong>
                </p>
                <p>
                  To comply with our legal and regulatory obligations we may
                  provide personal data to the following authorities:
                  <br />
                </p>
                <ul>
                  <li>Defra</li>
                  <li>Ofwat</li>
                  <li>Drinking Water Inspectorate</li>
                  <li>Environment Agency</li>
                  <li>Natural England</li>
                  <li>Public Health England</li>
                  <li>Environmental Health Authorities</li>
                  <li>CCWater</li>
                  <li>WATRS</li>
                  <li>Local Authorities</li>
                  <li>Police, Fire, Ambulance, and other emergency services</li>
                  <li>HSE</li>
                  <li>MOSL</li>
                </ul>
                <p>
                  <br />
                  We have legal obligations to share data with our regulators.
                  For example, we are required to share contact information with
                  our water regulator Ofwat (and third parties working on their
                  behalf) for the purposes of the water industry's Service
                  Incentive Mechanism. We also have to share any personal data
                  related to a water quality incident with the Drinking Water
                  Inspectorate (DWI). Occasionally if you are part of an
                  incident investigation there is a chance that data may be
                  shared with the Health and Safety Executive or the Environment
                  Agency if it is part of the reporting requirements.
                </p>
                <p>
                  <strong>
                    Obligations to share with your Waste Water providers
                  </strong>
                </p>
                <p>
                  We have legal requirements for sharing data with your waste
                  water companies Thames Water and Southern Water who operate in
                  our supply area.
                </p>
                <p>
                  We also undertake additional data processing on their behalf
                  by billing for them at the same time that we bill your water
                  charges. We have data processing agreements in place with both
                  Thames Water and Southern Water for this.
                </p>
                <p>
                  We also share information such as whether a property is
                  connected to mains water supply for the purposes of land and
                  drainage searches undertaken with waste water providers in
                  connection with sales and purchases of properties for example
                  CON29 Land and Drainage searches.
                </p>
                <p>
                  <strong>
                    Agreements we have with other organisations for sharing
                    information
                  </strong>
                </p>
                <p>
                  We have in place some data sharing agreements for mutual
                  benefits. For example, we have data sharing agreements with
                  some housing associations to help keep our customer data
                  accurate. This may involve names and addresses of customers.
                </p>
                <p>
                  If customers fall behind with their payments and we are unable
                  to locate them to chase their debt, we may share their data
                  with credit reference agencies that will help us to track
                  people’s locations for the purpose of collecting debt.
                </p>
                <p>
                  <strong>
                    Circumstances where we can pass on personal data without
                    consent
                  </strong>
                </p>
                <p>
                  Sometimes we are contacted by HMRC, the Department for Work
                  and Pensions (DWP), the police, fraud agencies or Immigration
                  asking for information about individual customers. Under the
                  Data Protection Requirements, we are permitted to share this
                  data with them in the support of the prevention and detection
                  of crime without your consent and you will not be notified
                  that this has been done.
                </p>
                <p>
                  <strong>
                    Ofwat surveys (customer service - “SIM”; C-Mex and D-Mex”)
                  </strong>
                </p>
                <p>
                  All household customers and developers who contact us may have
                  their contact information passed to a third party processor
                  acting on behalf of our water regulator Ofwat for the purposes
                  of regulating customer service in the water industry in
                  England and Wales. You may be contacted by this third party
                  about the service you received from South East Water and asked
                  to score our performance. This is not a marketing call and
                  your response is very important to us and Ofwat. As our water
                  regulator we are permitted to share certain details with them,
                  however, if you would rather we did not do this then please
                  let us know. The results of these surveys may be stored with
                  your water account.
                </p>
                <p>
                  <strong>Our surveys</strong>
                </p>
                <p>
                  We may use third parties, or our own employees, to help
                  contact our various types of customers to undertake water
                  consumption surveys to inform our water resource management
                  plan or for customer satisfaction or customer engagement
                  processes. These are not marketing calls and your response is
                  very important to us. If you would prefer not to participate
                  you can register yourself as ‘no contact’ with our customer
                  services team at any time. Please note this will not stop
                  general communications in relation to your account. The
                  results of these surveys are not stored on your water account
                  but are instead used by our customer engagement and water
                  resources team to look at improving engagement or planning
                  resources in the area.
                </p>
                <p>
                  We may also contact you to undertake a survey after you have
                  been in touch with us so that we can find out how satisfied
                  you were with the service you received. The results of this
                  survey will be stored on your water account. We may contact
                  you after this to see if we can improve our service to you.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Other third parties</h4>
                <p>
                  In future, we may also be permitted or required to share
                  information about you and your account with public authorities
                  and other utility companies in order to improve public service
                  delivery and ensure that priority customers can access
                  available support and discount schemes.
                </p>
                <p>
                  If we merge with another business entity or divest a part of
                  our business or carry out internal corporate restructuring,
                  your information may be disclosed to South East Water's new
                  business partners or owners or the new corporate entities.
                </p>
                <p>
                  In the event that we sell or buy any business or assets we may
                  disclose your personal data to the prospective seller or buyer
                  of such business or assets.
                </p>
                <p>
                  If South East Water Limited, or substantially all of its
                  assets, are acquired by a third party, personal data held by
                  it about its customers will be one of the transferred assets.
                </p>
                <p>
                  We may be required to share your personal information with
                  others as required by law, for example for fraud prevention.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Trusted partners we use who may have access to your data
                </h4>
                <p>
                  We use trusted partners to help us process your data and
                  provide services to you. All of our data processors have a
                  binding contract with us that restricts their access to and
                  handling of your personal data to only what is necessary in
                  performance of their contract. Our contracts detail how
                  personal data should be handled and protected while being
                  processed by any third party. We conduct due diligence checks
                  on third party processors to ensure they comply with their
                  obligations to us.
                </p>
                <p>
                  We use software providers for different systems, like our
                  billing system, works order system, and meter reading system,
                  to support us with the large amounts of data that we need to
                  manage. We use companies to assist us with printing bills and
                  customer communications and to help us process payments
                  including card payments, payment cards and to process cheques.
                </p>
                <p>
                  We work closely with our primary contractor Clancy Docwra for
                  mains replacement and pipe repairs and they will have access
                  to some customer information relevant to their work.
                </p>
                <p>
                  We use companies to assist us with recovering debt from
                  customers. They have access to relevant customer information
                  on our billing system and may use your data to contact you or
                  visit your home in fulfilment of their contract with us.
                </p>
                <p>
                  We use our commercial arm, Waterlink (which is still South
                  East Water) to make leak repairs at customer properties. If
                  you contact us about a leak in your home and are eligible for
                  a visit, your data will be shared with Waterlink in order to
                  provide this function on our behalf.
                </p>
                <p>
                  From time to time we require legal advice and may need to
                  share your personal data with our legal advisers or our
                  insurance company or other professional advisors in order to
                  obtain advice or make a claim.
                </p>
                <p>
                  South East Water also uses a trusted partner service to help
                  maintain the security and performance of the South East Water
                  website. To deliver this service the trusted partner processes
                  the IP addresses of visitors to the website.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Where we store your information and how we keep it safe
                </h4>
                <p>
                  We will keep the details collected through the AquaSmart
                  website as long as the purpose we need it for is still valid.
                  In most cases this is expected to be.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  How long we will keep your information
                </h4>
                <p>
                  We keep customer billing and correspondence for seven years
                  after the account has been closed. We hold call recordings for
                  six months, unless it is required for a legal case in which
                  case it will be kept for the length of the legal case.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Marketing</h4>
                <p>
                  It is our responsibility and legal duty to promote water
                  efficiency. We use a number of methods, including direct
                  marketing on occasion. This may include promoting products and
                  services we provide. We may engage with a third party to
                  provide this service on our behalf. If you are a South East
                  Water customer we may be promoting the AquaSmart website to
                  you.
                </p>
                <p>
                  Such activities will be in accordance with our legitimate
                  interests. However if you wish to exercise your right to
                  object to such marketing please contact us.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">
                  Automated decision making and profiling
                </h4>
                <p>
                  We do not currently conduct any automated decision making,
                  including profiling, as defined by the Data Protection
                  Requirements.
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Your data protection rights</h4>
                <p>
                  You have certain rights in relation to your personal
                  information, although those rights will not apply in all cases
                  or to all information that we hold about you. For example, we
                  may need to continue to hold and process information to
                  establish, exercise or defend our legal rights. Alternatively,
                  the rights may not be enforceable until the General Data
                  Protection Regulation comes into force on 25&nbsp;May 2018. We
                  will tell you if this is the case when you contact us.
                </p>
                <p>
                  You have the <strong>right to be informed</strong> and we will
                  try to be as transparent as possible in our interactions with
                  you. Any time you give us personal information you have a
                  right to be informed about why we need it and how we will use
                  it. You can find most of the information you need right here
                  in this privacy notice.
                </p>
                <p>
                  You have right of access to any personal data we hold about
                  you. You can contact us at any time to gain information about
                  what data we hold, why we hold it, where we got it from and
                  how we will use and share it. You may choose to write to us to
                  exercise your <strong>subject access right.</strong> If you
                  make a formal written request we will respond to acknowledge
                  your request and check your identity, we might also ask you
                  for information about what you are looking for to help us make
                  sure we meet your request fully. You will receive a full
                  response at the earliest opportunity but in any event within
                  30 calendar days of your request. There is no charge made for
                  this by South East Water.
                </p>
                <p>
                  To make a request to South East Water for any personal
                  information we may hold you need to put the request in writing
                  addressing it to our Data Protection Officer, contact us using
                  the email address provided below. If you agree, we will try to
                  deal with your request informally, for example by providing
                  you with the specific information you need over the telephone.
                </p>
                <p>
                  You have the right to have any personal data we hold{" "}
                  <strong>corrected</strong> at any time if you believe it to be
                  inaccurate e.g. the spelling of your name or your contact
                  information.
                </p>
                <p>
                  You also have the right to ask for our processing of your
                  personal data to be <strong>restricted</strong> for example if
                  you are contesting the accuracy of data we are using to bill
                  you. We will restrict our processing while we verify the
                  accuracy of the data that we hold.
                </p>
                <p>
                  You can also ask for certain information about you to be{" "}
                  <strong>deleted</strong> for example if you are moving out of
                  our supply area and you are not behind with your water bill
                  payments or if you are no longer part of a discount or support
                  scheme. We will not be able to delete any data relating to
                  outstanding debts until the debt has been repaid as we have a
                  legal obligation to collect debt. We will also not be able to
                  delete your information if there has been a water quality
                  concern related to the water supply for your property, as this
                  data must be kept for seven years, or any information that we
                  need to retain in connection with our Water Supply Licence.
                </p>
                <p>
                  You have the right to <strong>data portability</strong> where
                  our lawful basis for processing is consent and where
                  processing is automated, although we don't currently carry out
                  any such processing. If we do in future, you can make a
                  request and this data can be exported from our systems for
                  you.&nbsp;
                </p>
                <p>
                  You have the right to ask us not to process your personal data
                  for <strong>marketing or profiling purposes</strong>. We will
                  ask for your consent (before collecting your data) if we
                  intend to use your data for such purposes or if we intend to
                  disclose your information to any third party for such
                  purposes. You can exercise your right to prevent such
                  processing by checking certain boxes on the forms we use to
                  collect your data. You can also exercise the right at any
                  time.
                </p>
                <p>
                  To exercise any of your rights please contact us at{" "}
                  <a
                    href="mailto:dataprotection@southeastwater.co.uk"
                    className=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>dataprotection@southeastwater.co.uk</strong>
                  </a>
                </p>
              </div>
              <br />
              <div>
                <h4 className="text-teal">Changes to this privacy notice</h4>
                <p>
                  This privacy notice will be kept up to date as our business
                  and internal practices and/or the Data Protection Requirements
                  change and so may be subject to change at any time to reflect
                  any changing activities. The most up to date version will
                  always be available on our website.
                </p>
                <p>
                  We will not make any use of your personal information that is
                  inconsistent with the original purpose(s) for which it was
                  collected or obtained (if we intend to do so, we will notify
                  you in advance wherever possible) or otherwise than is
                  permitted by the Data Protection Requirements
                </p>
                <p>
                  We keep our privacy notice under regular review. This privacy
                  notice was last updated on&nbsp;25 May 2018.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrivacyPage;
